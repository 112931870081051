import React, { useState, useEffect, useRef } from 'react';

import './FindMore.scss';
import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import { ListArrow } from '../../Atoms/Icons/Icons';
import { trackPlaquetteEvent } from '../../Utils/Tracking';

let classNames = require('classnames');

const FindMore = ({ componentData, alignment, page_name, page_type, activity, zone, color = 'dark_bleu' }) => {
  const intl = useIntl();
  return (
    <div
      className={`findMoreBlock ${alignment === 'right' ? 'alignLeft' : 'alignRight'
        }`}
    >
      <div className="findMore">
        <div className={classNames('titleDesktop', 'color_reverse_' + color)}>
          {intl.formatMessage({
            id: 'detailsActivities.findMore',
          })}
        </div>
        <ul>
          {componentData.map((findMore, i) => (
            <li key={i}>
              <a target="_blank" href={findMore?.field_link?.url} onClick={() => trackPlaquetteEvent({
                'event': 'clic_plaquette',
                'page_name': page_name,
                'activity': activity,
                'content_name': findMore?.field_subtitle,
                'page_type': page_type,
                'zone': zone,
                'external_link_url': findMore?.field_link?.url?.indexOf('.solutions-colas') > 0 ? findMore?.field_link?.url : '/',
                'external_link_text': findMore?.field_link?.url?.indexOf('.solutions-colas') > 0 ? 'site_solutions' : ''
              })}>
                <div className={classNames('listTitle', 'color_' + color)}>
                  <ListArrow color="color_yellow" aria-hidden="true" />
                  <h4>{findMore?.field_subtitle}</h4>
                </div>
                <div className={'color_' + color}
                  dangerouslySetInnerHTML={{
                    __html: findMore?.field_text?.processed,
                  }}
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FindMore;
