import * as React from 'react';

import PropTypes from 'prop-types';

import useMedias from '../../../hooks/useMedias';
import { Button } from '../../../Molecules/Button/Button';
import TitleBlock from '../../../Molecules/TitleBlock/TitleBlock';

import './TextBlock.scss';
let classNames = require('classnames');

const TextBlock = ({
  colorTitle,
  colorDescription,
  title,
  text,
  textList,
  titleBackground,
  titleHash,
  cta,
}) => {
  const { ctaText, ctaLink, ctaTarget } = cta || {};
  return (
    <div className={classNames('text_block')}>
      <div className={classNames('text_container')}>
        <div
          className={classNames('text_center', {
            no_title: !title && !titleHash,
          })}
        >
          {title && !titleHash && (
            <TitleBlock
              title={title}
              titleBackground={titleBackground}
              color={colorTitle}
            />
          )}
          {titleHash && <div className="title_hash">{title}</div>}
          {text && (
            <div
              className={colorDescription ? 'color_' + colorDescription : ''}
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
          )}
          {textList && (
            <ul className={colorDescription ? 'color_' + colorDescription : ''}>
              {textList.map((textItem, index) => (
                <li key={index}>{textItem.text}</li>
              ))}
            </ul>
          )}
          {ctaText && ctaLink && (
            <Button
              label={ctaText}
              link={ctaLink}
              target={ctaTarget ? '_blank' : '_self'}
            />
          )}
        </div>
      </div>
    </div>
  );
};

TextBlock.defaultProps = {
  text: '',
  text_color: 'color_dark_bleu',
  titleBackground: false,
};

TextBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  cta: PropTypes.object,
};

export default TextBlock;
